<template>
  <v-card outlined class="ma-4">

    <!-- header -->
    <v-toolbar flat dense color="primary">
      <v-toolbar-title>Rekordy</v-toolbar-title>
    </v-toolbar>

    <!-- event -->
    <v-toolbar-title v-if="tempNoDataForGLS === false" class="body-1 ml-3 mt-3 mr-3">Konkurencja</v-toolbar-title>
    <v-chip-group v-if="tempNoDataForGLS === false" column v-model="event">
      <v-chip v-for="(e, index) in events" :key="e" outlined
              :color="index === event ? 'primary' : ''"
              class="ma-1" style="padding-top: 2px; padding-left: 13px"
              @click="loadRecords(e, undefined)">
        <v-icon>{{ $utils.events.getEventIcon(e) }}</v-icon>
      </v-chip>
    </v-chip-group>

    <!-- category -->
    <v-toolbar-title v-if="tempNoDataForGLS === false" class="body-1 ml-3 mt-3 mr-3">Kategoria</v-toolbar-title>
    <v-chip-group column v-model="category" v-if="tempNoDataForGLS === false">
      <v-chip v-for="(c, index) in categories" :key="index" outlined
              :color="index === category ? 'primary' : ''"
              class="ma-1" style="padding-top: 2px; padding-left: 13px"
              @click="loadRecords(undefined, c)">
        {{ getCategoryName(c) }}
      </v-chip>
    </v-chip-group>

    <v-divider/>

    <!-- records -->
    <div v-if="state === 'ok'">
      <v-card v-for="(cat, i) in records" v-bind:key="i"
              elevation="0" class="ma-2">
        <v-card-title>
          <v-icon left color="primary">{{ '$event-' + cat.event }}</v-icon>
          {{ cat.title }}
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">Typ</th>
                <th class="text-left">Zawodnik</th>
                <th class="text-left">Wynik</th>
                <th class="text-left">Zawody</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(record, index) in cat.records" :key="index">
                <td class="text-left">{{ record[4] === 's' ? 'Najlepsze' : 'Średnia' }}</td>
                <td class="text-left">{{ record[1] }}</td>
                <td class="text-left">
                  {{ $utils.results.parseResult(record[5], $utils.events.EVENT_ICONS.indexOf(cat.event)) }}
                </td>
                <td class="text-left">{{ record[3] }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>

    <!-- loading -->
    <v-skeleton-loader v-if="state === 'loading'" type="card"/>

    <!-- error -->
    <v-alert v-if="state === 'error'">
      <v-icon left color="error">mdi-alert-circle-outline</v-icon>
      Wystąpił nieoczekiwany błąd.
    </v-alert>

  </v-card>
</template>

<script>
export default {
  name: 'Records',
  data: () => ({
    state: 'loading',
    event: undefined, events: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    category: 0, categories: ['overall', '8', '10', '12', '14', '16', 'women', 'parents'],
    records: [],
    tempNoDataForGLS: false,
  }),
  created() {
    this.loadRecords(undefined, 'overall');
  },
  methods: {
    getCategoryName(category) {
      if (category === 'overall') return 'Open';
      if (category === '8') return 'Do lat 8';
      if (category === '10') return 'Do lat 10';
      if (category === '12') return 'Do lat 12';
      if (category === '14') return 'Do lat 14';
      if (category === '16') return 'Do lat 16';
      if (category === 'k') return 'Kobiety';
      if (category === 'r') return 'Rodzice';
      if (category === 'women') return 'Kobiety';
      if (category === 'parents') return 'Rodzice';
      return '';
    },
    loadRecords(event, category) {
      if (this.$env.APP_MODE !== 'ppo') {
        this.tempNoDataForGLS = true;
        this.records = [{
          "title": "Kostka 3x3x3",
          "event": "333",
          "records": [
            ["00TEMP00", "Olaf Kuźmiński", "temp", "GLS I 2023", "s", "507", "333"],
            ["00TEMP00", "Olaf Kuźmiński", "temp", "GLS I 2023", "a", "592", "333"]]
        }, {
          "title": "Kostka 2x2x2",
          "event": "222",
          "records": [
            ["00TEMP00", "Teodor Zajder", "temp", "GLS II 2023", "s", "76", "222"],
            ["00TEMP00", "Maciej Czapiewski", "temp", "GLS Cup II 2018", "a", "145", "222"]]
        }, {
          "title": "Kostka 4x4x4",
          "event": "444",
          "records": [
            ["00TEMP00", "Tymon Kolasiński", "temp", "GLS Cup I 2019", "s", "2523", "444"],
            ["00TEMP00", "Tymon Kolasiński", "temp", "GLS Cup I 2019", "a", "2850", "444"]]
        }, {
          "title": "Kostka 5x5x5",
          "event": "555",
          "records": [
            ["00TEMP00", "Michał Halczuk", "temp", "GLS Cup III 2018", "s", "4682", "555"],
            ["00TEMP00", "Michał Halczuk", "temp", "GLS Cup III 2018", "a", "5586", "555"]]
        }, {
          "title": "Kostka 6x6x6",
          "event": "666",
          "records": [
            ["00TEMP00", "Michał Halczuk", "temp", "GLS Cup III 2018", "s", "9421", "666"],
            ["00TEMP00", "Michał Halczuk", "temp", "GLS Cup III 2018", "a", "10109", "666"]]
        }, {
          "title": "Kostka 7x7x7",
          "event": "777",
          "records": [
            ["00TEMP00", "Michał Halczuk", "temp", "GLS Cup III 2018", "s", "14814", "777"],
            ["00TEMP00", "Michał Halczuk", "temp", "GLS Cup III 2018", "a", "14871", "777"]]
        }, {
          "title": "3x3x3 Bez patrzenia",
          "event": "333bf",
          "records": [
            ["00TEMP00", "Krzysztof Bober", "temp", "GLS III 2023", "s", "1901", "333bf"],
            ["00TEMP00", "Kamil Przybylski", "temp", "GLS III 2023", "a", "2276", "333bf"]]
        }, {
          "title": "3x3x3 Najmniejsza liczba ruchów",
          "event": "333fm",
          "records": [
            ["00TEMP00", "Kacper Rafalski", "temp", "GLS Cup III 2019", "s", "20", "333fm"],
            ["00TEMP00", "Kacper Rafalski", "temp", "GLS Cup III 2019", "a", "2467", "333fm"]]
        }, {
          "title": "3x3x3 Jedną ręką",
          "event": "333oh",
          "records": [
            ["00TEMP00", "Karol Zakrzewski", "temp", "GLS Cup V 2018", "s", "875", "333oh"],
            ["00TEMP00", "Bartosz Karpiński", "temp", "GLS II 2023", "a", "1076", "333oh"]]
        }, {
          "title": "Clock",
          "event": "clock",
          "records": [
            ["00TEMP00", "Maciej Skowroński", "temp", "GLS I 2023", "s", "390", "clock"],
            ["00TEMP00", "Szymon Grodzki", "temp", "GLS I 2023", "a", "515", "clock"]]
        }, {
          "title": "Megaminx",
          "event": "minx",
          "records": [
            ["00TEMP00", "Jan Zych", "temp", "GLS Cup Final 2018", "s", "3584", "minx"],
            ["00TEMP00", "Jan Zych", "temp", "GLS Cup IV 2018", "s", "4020", "minx"]]
        }, {
          "title": "Pyraminx",
          "event": "pyram",
          "records": [
            ["00TEMP00", "Tymon Kolasiński", "temp", "GLS Cup I 2018", "s", "136", "pyram"],
            ["00TEMP00", "Tymon Kolasiński", "temp", "GLS Final 2017", "a", "202", "pyram"]]
        }, {
          "title": "Skewb",
          "event": "skewb",
          "records": [
            ["00TEMP00", "Cezary Mach", "temp", "GLS III 2023", "s", "105", "skewb"],
            ["00TEMP00", "Cezary Mach", "temp", "GLS III 2023", "a", "224", "skewb"]]
        }, {
          "title": "Square-1",
          "event": "sq1",
          "records": [
            ["00TEMP00", "Michał Krasowski", "temp", "GLS II 2023", "s", "525", "sq1"],
            ["00TEMP00", "Michał Krasowski", "temp", "GLS II 2023", "a", "723", "sq1"]]
        }, {
          "title": "4x4x4 Bez patrzenia",
          "event": "444bf",
          "records": [
            ["00TEMP00", "Grzegorz Jałocha", "temp", "GLS Cup V 2018", "s", "13720", "444bf"],
            ["00TEMP00", "Grzegorz Jałocha", "temp", "GLS Cup V 2018", "a", "16842", "444bf"]]
        }, {
          "title": "5x5x5 Bez patrzenia",
          "event": "555bf",
          "records": [
            ["00TEMP00", "Grzegorz Jałocha", "temp", "GLS Cup IV 2018", "s", "35506", "555bf"]]
        }, {
          "title": "3x3x3 Wiele kostek bez patrzenia",
          "event": "333mbf",
          "records": [
            ["00TEMP00", "Witali Bułatow", "temp", "GLS Final 2017", "s", "0700355300", "333mbf"]]
        }]; // 0700355300
        this.state = 'ok';
        return;
      }

      if (event != null) {
        this.category = undefined;
        event = this.$utils.events.EVENT_ICONS[event];
        this.$http.get(window.location.origin + '/data/records/' + event + '.csv?v=' + (new Date().getTime()).toString()).then((res) => {
          let records = res.data.split('\n');
          records.pop();
          for (let i = 0; i < records.length; i++)
            records[i] = records[i].trim().split(',');
          const CAT = ['parents', 'women', '16', '14', '12', '10', '8', 'overall'].reverse();
          let out = {};
          for (let i = 0; i < CAT.length; i++)
            out[CAT[i]] = {title: this.getCategoryName(CAT[i]), event: event, category: CAT[i], records: []};
          for (let i = 0; i < records.length; i++)
            out[records[i][6]].records.push(records[i]);
          out = Object.values(out).filter((item) => item.records.length !== 0).sort((x, y) => CAT.indexOf(x.category) - CAT.indexOf(y.category));
          this.records = out;
          this.state = 'ok';
        }).catch((e) => {
          this.state = 'error';
          console.log(e);
        });
      }
      if (category != null) {
        this.event = undefined;
        this.$http.get(window.location.origin + '/data/records/' + category + '.csv?v=' + (new Date().getTime()).toString()).then((res) => {
          let records = res.data.split('\n');
          records.pop();
          for (let i = 0; i < records.length; i++)
            records[i] = records[i].trim().split(',');
          const EVT = this.$utils.events.EVENT_ICONS;
          let out = {};
          for (let i = 0; i < EVT.length; i++)
            out[EVT[i]] = {title: this.$utils.events.getEventName(EVT.indexOf(EVT[i])), event: EVT[i], records: []};
          for (let i = 0; i < records.length; i++)
            out[records[i][6]].records.push(records[i]);
          out = Object.values(out).filter((item) => item.records.length !== 0).sort((x, y) => EVT.indexOf(x.event) - EVT.indexOf(y.event));
          this.records = out;
          this.state = 'ok';
        }).catch((e) => {
          this.state = 'error';
          console.log(e);
        });
      }
    }
  }
};
</script>
